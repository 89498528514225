import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Header.css';
function Header() {
    let currentSite = null;
    if (window.location.host === "disabled.decon.app")
        currentSite = "Mainnet";
    if (window.location.host === "testnet.decon.app")
        currentSite = "Testnet";
    return (_jsxs("div", Object.assign({ className: "header" }, { children: [currentSite ?
                _jsxs("div", Object.assign({ className: "network-selector dropdown" }, { children: [_jsx("button", Object.assign({ className: "btn btn-dark btn-primary btn-sm dropdown-toggle", type: "button", id: "dropdownNetwork", "data-bs-toggle": "dropdown", "aria-expanded": "false" }, { children: currentSite })), _jsxs("ul", Object.assign({ className: "dropdown-menu dropdown-menu-dark", "aria-labelledby": "dropdownNetwork" }, { children: [_jsx("li", { children: _jsx("button", Object.assign({ className: "dropdown-item", type: "button", onClick: () => window.location.href = "https://decon.app" }, { children: "Mainnet" })) }), _jsx("li", { children: _jsx("button", Object.assign({ className: "dropdown-item", type: "button", onClick: () => window.location.href = "https://testnet.decon.app" }, { children: "Testnet" })) })] }))] }))
                : '', _jsx("span", Object.assign({ className: 'text-center' }, { children: "decon" }))] })));
}
export default Header;
