import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './social-list.css';
function SocialList({ socials, wallet, selectSocialTarget, selectUser }) {
    const renderSocial = function (social, level) {
        return (_jsxs("div", Object.assign({ className: level % 2 == 0 ? "social-thread social-thread-even" : "social-thread social-thread-odd" }, { children: [_jsx("a", { "data-bs-toggle": "collapse", href: `#social-${social.guid}`, role: "button", "aria-expanded": "true", "aria-controls": `social-${social.guid}`, className: 'social-expander' }), _jsxs("div", Object.assign({ id: `social-${social.guid}`, className: "collapse show" }, { children: [_jsxs("span", Object.assign({ className: "social-user" }, { children: ["\uD83D\uDC64", _jsx("button", Object.assign({ type: "button", className: "user-button btn btn-link", "data-bs-toggle": "modal", "data-bs-target": "#userModal", onClick: () => selectUser(social.from) }, { children: _jsx("b", { children: social.from }) }))] })), _jsx("span", Object.assign({ className: "social-date" }, { children: new Date(social.ts * 1000).toString() })), _jsx("span", Object.assign({ className: "social-content" }, { children: social.content })), _jsx("button", Object.assign({ type: "button", className: `social-reply-button btn btn-sm btn-dark mx-2 ${wallet ? '' : 'disabled'}`, disabled: !wallet, "data-bs-toggle": "modal", "data-bs-target": "#createSocialModal", onClick: () => selectSocialTarget(social.guid) }, { children: "Reply" })), _jsx("div", { children: socials.filter(x => x.parent && x.parent == social.guid).map(x => {
                                return renderSocial(x, level + 1);
                            }) })] }), `social-${social.guid}`)] }), `main-social-${social.guid}`));
    };
    if (!socials || socials.length === 0)
        return _jsx("div", Object.assign({ className: "social-list" }, { children: "No socials found." }));
    return (_jsx("div", Object.assign({ className: "social-list" }, { children: socials.filter(social => !social.parent).map((social) => {
            return _jsxs("div", Object.assign({ className: "social" }, { children: [_jsx("h4", Object.assign({ className: "social-title" }, { children: social.title })), renderSocial(social, 0)] }), `social-post-${social.guid}`);
        }) })));
}
export default SocialList;
