var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockfrostApiClient } from "./clients/blockfrost";
import { KoiosApiClient } from "./clients/koios";
import NinjaConfig from "../ninjaConfig";
import { getSettings } from "./settings";
const Cardano = await (() => __awaiter(void 0, void 0, void 0, function* () { return yield require("@emurgo/cardano-serialization-lib-browser"); }))();
const Message = await (() => __awaiter(void 0, void 0, void 0, function* () { return yield require("@emurgo/cardano-message-signing-browser"); }))();
const Buffer = require("buffer/").Buffer;
const yaml = require("js-yaml");
const db = require("../db/dbConnection");
let database = db.getDb();
let apiClient;
function initApiClient(provider, apiKey) {
    console.log(provider);
    console.log(apiKey);
    if (provider == "KOIOS") {
        return new KoiosApiClient(apiKey);
    }
    else {
        return new BlockfrostApiClient(apiKey);
    }
}
function updateSettings(isProd, provider, apiKey) {
    return __awaiter(this, void 0, void 0, function* () {
        yield database.insertSettings({
            isProd: isProd,
            provider: provider,
            apiKey: apiKey,
        });
    });
}
function setSettings(isProd, provider, key) {
    return __awaiter(this, void 0, void 0, function* () {
        yield updateSettings(isProd, provider, key);
        apiClient = initApiClient(provider, key);
    });
}
function clearDb() {
    return __awaiter(this, void 0, void 0, function* () {
        yield database.clearDatabase();
    });
}
function processTransaction(txHash, transactionTime, metadataJsonObj) {
    return __awaiter(this, void 0, void 0, function* () {
        //let metadataJsonObj = JSON.parse(metadataJson);
        // rebuild signature
        let signature = "";
        for (let i = 0; i < 50; i++) {
            let index = `s_${i}`;
            if (!metadataJsonObj[index])
                break;
            signature += metadataJsonObj[index];
        }
        // rebuild key
        let key = "";
        for (let i = 0; i < 50; i++) {
            let index = `k_${i}`;
            if (!metadataJsonObj[index])
                break;
            key += metadataJsonObj[index];
        }
        const message = Message.COSESign1.from_bytes(Buffer.from(Buffer.from(signature, "hex"), "hex"));
        const headermap = message.headers().protected().deserialized_headers();
        const address = Cardano.Address.from_bytes(headermap.header(Message.Label.new_text("address")).as_bytes());
        let payloadBytes = message.payload();
        let payload = Buffer.from(payloadBytes, "hex");
        let payloadObj = yaml.load(payload.toString("utf-8"));
        console.log(payloadObj);
        let username = payloadObj.from;
        let guid = payloadObj.guid;
        if (!username) {
            console.log("Invalid username");
            return;
        }
        if (!guid) {
            console.log("Invalid guid");
            return;
        }
        // validate guid to prevent replay attacks
        let isGuidAlreadyUsed = yield database.findGuid(guid);
        if (isGuidAlreadyUsed) {
            console.log("Guid already used!");
            return;
        }
        // get the stake address associated with the user
        let stakeAddress = "";
        let isFirstTimeUser = false;
        let user = yield database.findUserByName(username);
        if (!user) {
            isFirstTimeUser = true;
            stakeAddress = payloadObj.stakeAddr;
            if (!stakeAddress) {
                console.log("Invalid stake address");
                return;
            }
            if (yield database.findUserByStakeAddr(stakeAddress)) {
                console.log("Stake address already used");
                return;
            }
        }
        else {
            stakeAddress = user.stakeAddress;
        }
        if (address.to_bech32() !== stakeAddress) {
            console.log("Signature address doesnt match registered stake address");
            return;
        }
        const coseKey = Message.COSEKey.from_bytes(Buffer.from(key, "hex"));
        const publicKey = Cardano.PublicKey.from_bytes(coseKey
            .header(Message.Label.new_int(Message.Int.new_negative(Message.BigNum.from_str("2"))))
            .as_bytes());
        const stakeKeyHash = publicKey.hash();
        const reconstructedAddress = Cardano.RewardAddress.new(address.network_id(), Cardano.StakeCredential.from_keyhash(stakeKeyHash));
        if (address.to_bech32() !== reconstructedAddress.to_address().to_bech32()) {
            console.log("Signature address doesnt match public key address");
            return;
        }
        const data = message.signed_data().to_bytes();
        const ed25519Sig = Cardano.Ed25519Signature.from_bytes(message.signature());
        if (!publicKey.verify(data, ed25519Sig)) {
            throw new Error(`Message integrity check failed (has the message been tampered with?)`);
        }
        else {
            console.log("Message verified!");
        }
        if (isFirstTimeUser) {
            yield database.insertUser({
                user: username,
                userinfo: payloadObj.userinfo,
                stakeAddress: stakeAddress,
                publicKey: payloadObj.publicKey,
                ts: transactionTime,
            });
        }
        yield database.insertGuid({ guid: guid });
        if (payloadObj.type == "post") {
            handlePost(username, transactionTime, guid, payloadObj);
        }
        else if (payloadObj.type == "msg") {
            handleMessage(username, transactionTime, guid, payloadObj);
        }
        else if (payloadObj.type == "social") {
            handleSocial(username, transactionTime, guid, payloadObj);
        }
        else {
            console.log("Error: Could not determine type of transaction");
            console.log(payloadObj);
        }
        return true;
    });
}
function handlePost(username, transactionTime, guid, payloadObj) {
    return __awaiter(this, void 0, void 0, function* () {
        // TODO validate fields
        yield database.insertPost({
            from: username,
            ts: transactionTime,
            guid: guid,
            title: payloadObj.title,
            price: payloadObj.price,
            description: payloadObj.description,
            category: payloadObj.category,
            subcategory: payloadObj.subcategory,
            location: payloadObj.location,
            sublocation: payloadObj.sublocation,
        });
        if (payloadObj.category)
            yield database.incrementCategory({ category: payloadObj.category });
        if (payloadObj.subcategory)
            yield database.incrementSubcategory({
                subcategory: payloadObj.subcategory,
            });
        if (payloadObj.location)
            yield database.incrementLocation({ location: payloadObj.location });
        if (payloadObj.sublocation)
            yield database.incrementSublocation({
                sublocation: payloadObj.sublocation,
            });
    });
}
function handleSocial(username, transactionTime, guid, payloadObj) {
    return __awaiter(this, void 0, void 0, function* () {
        // TODO validate fields
        let social = {
            from: username,
            ts: transactionTime,
            guid: guid,
            content: payloadObj.content,
        };
        if (!payloadObj.parent) {
            // it is a new thread
            social.title = payloadObj.title;
            social.category = payloadObj.category;
        }
        else {
            // it is a reply
            social.parent = payloadObj.parent;
        }
        yield database.insertSocial(social);
        if (social.category)
            yield database.incrementSocialCategory({ category: payloadObj.category });
    });
}
function handleMessage(username, transactionTime, guid, payloadObj) {
    return __awaiter(this, void 0, void 0, function* () {
        // TODO validate fields
        console.log("Handling message...");
        console.log(payloadObj);
        yield database.insertMessage({
            from: username,
            ts: transactionTime,
            guid: guid,
            to: payloadObj.to,
            srcKey: payloadObj.srcKey,
            dstKey: payloadObj.dstKey,
            iv: payloadObj.iv,
            message: payloadObj.message,
        });
    });
}
function populateDb(lastBlock) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataLabel = new NinjaConfig().getCfg().METADATA_LABEL;
        let lastTransaction = {
            hash: "",
            block: lastBlock !== null && lastBlock !== void 0 ? lastBlock : 1,
            ts: 123,
            metadata: null,
        };
        if (!apiClient) {
            let settings = yield getSettings();
            apiClient = initApiClient(settings.provider, settings.apiKey);
        }
        let transactions = yield apiClient.getTransactions(lastTransaction);
        for (let transaction of transactions) {
            // check if tx_hash already processed
            let isAlreadyProcessed = yield database.findTransaction(transaction.hash);
            if (isAlreadyProcessed)
                continue;
            console.log(`Processing tx ${transaction.hash}...`);
            // process json metadata
            console.log("Processing: ", transaction.hash);
            try {
                yield processTransaction(transaction.hash, transaction.ts, transaction.metadata[metadataLabel]);
            }
            catch (exception) {
                console.log(`Failed processing transaction ${transaction.hash}: ${exception}`);
            }
            // register tx_hash to our db, so it is skipped next time
            database.insertTransaction({ transactionId: transaction.hash });
        }
        lastBlock = Math.max(lastBlock, ...transactions.map((tx) => tx.block));
        console.log(lastBlock);
        return lastBlock;
    });
}
export { populateDb, setSettings, clearDb };
