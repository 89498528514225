var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './settings-list.css';
import { setSettings, clearDb } from '../../api/data-gatherer';
import { getSettings } from '../../api/settings';
import { useState, useEffect } from 'react';
function SettingsList({ closeSettings, refreshQueries }) {
    const [selectOption, setSelectOption] = useState('');
    const [apiKeyValue, setApiKeyValue] = useState('');
    const [isProd, setIsProd] = useState(true);
    useEffect(() => {
        const setOption = () => __awaiter(this, void 0, void 0, function* () {
            let settings = yield getSettings();
            setSelectOption(settings.provider);
            setApiKeyValue(settings.apiKey);
            setIsProd(settings.isProd);
        });
        setOption();
    }, []);
    const handleSaveButtonClick = () => {
        setSettings(isProd, selectOption, apiKeyValue);
    };
    const handleClearDbClick = () => __awaiter(this, void 0, void 0, function* () {
        yield setSettings(isProd, selectOption, apiKeyValue);
        yield clearDb();
        refreshQueries();
        closeSettings();
    });
    return (_jsxs("div", Object.assign({ className: "settings-list" }, { children: [_jsxs("span", Object.assign({ className: "setting" }, { children: [_jsxs("span", Object.assign({ className: "setting-row" }, { children: [_jsx("b", { children: "Data provider:" }), _jsxs("select", Object.assign({ value: selectOption, onChange: (event) => setSelectOption(event.target.value) }, { children: [_jsx("option", Object.assign({ value: "BLOCKFROST" }, { children: "Blockfrost" })), _jsx("option", Object.assign({ value: "KOIOS" }, { children: "Koios" }))] }))] })), _jsxs("span", Object.assign({ className: "setting-row" }, { children: ["API key: ", _jsx("input", { value: apiKeyValue, onChange: (event) => setApiKeyValue(event === null || event === void 0 ? void 0 : event.target.value) })] })), _jsxs("span", Object.assign({ className: "setting-row" }, { children: [_jsx("input", { type: "checkbox", checked: !isProd, onChange: () => setIsProd(!isProd) }), " Cardano preprod [requires DB reset]"] })), _jsx("button", Object.assign({ type: "button", className: "btn btn-dark", onClick: handleSaveButtonClick }, { children: "Save" }))] })), _jsx("span", Object.assign({ className: "setting" }, { children: _jsx("button", Object.assign({ type: "button", className: "btn btn-dark", onClick: handleClearDbClick }, { children: "Clear local DB" })) })), _jsx("br", {}), _jsx("span", Object.assign({ className: "setting" }, { children: _jsx("button", Object.assign({ type: "button", className: "btn btn-dark", onClick: closeSettings }, { children: "Close settings" })) }))] })));
}
export default SettingsList;
