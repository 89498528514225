var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import NinjaConfig from "../../ninjaConfig";
class KoiosApiClient {
    constructor(apiKey) {
        this.apiKey = "";
        this.apiKey = apiKey;
    }
    getTransactions(fromTransaction) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let url = new NinjaConfig().getCfg().KOIOS_URL;
            console.log(fromTransaction);
            let lastBlock = (_a = fromTransaction === null || fromTransaction === void 0 ? void 0 : fromTransaction.block) !== null && _a !== void 0 ? _a : 1;
            const txRequestConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.apiKey,
                },
                method: 'post',
                url: `${url}/address_txs`,
                data: {
                    "_addresses": [
                        new NinjaConfig().getCfg().WALLET_ADDRESS
                    ],
                    "_after_block_height": lastBlock + 1
                }
            };
            const txResponse = yield axios(txRequestConfig);
            if (txResponse.status != 200) {
                console.log("Tx api request failed!", txResponse);
                throw new Error("tx api request failed:" + txResponse);
            }
            let transactions = txResponse.data.map(tx => {
                return {
                    hash: tx.tx_hash,
                    block: tx.block_height,
                    ts: tx.block_time,
                    metadata: null,
                };
            });
            if (!transactions) {
                console.log('no tx hashes...');
                return [];
            }
            const requestConfig = {
                headers: {
                    Authorization: 'Bearer ' + this.apiKey,
                },
                method: 'post',
                url: `${url}/tx_metadata`,
                data: {
                    "_tx_hashes": transactions.map(tx => tx.hash)
                }
            };
            const response = yield axios(requestConfig);
            if (response.status != 200) {
                console.log("Api request failed!", response);
                throw new Error("api request failed:" + response);
            }
            for (let tx of response.data) {
                let transaction = transactions.find(x => x.hash == tx.tx_hash);
                if (transaction)
                    transaction.metadata = tx.metadata;
            }
            console.log(transactions);
            transactions.sort((a, b) => a.block - b.block);
            return transactions;
        });
    }
}
export { KoiosApiClient };
