var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Fetcher.css";
import { useState, useEffect } from "react";
import { populateDb } from "../../api/data-gatherer";
import NinjaConfig from "../../ninjaConfig";
function Fetcher({ socials, refreshQueries }) {
    const [page, setPage] = useState(1);
    const fetchData = () => __awaiter(this, void 0, void 0, function* () {
        try {
            console.log(page);
            const newPage = yield populateDb(page);
            if (page != newPage) {
                console.log("Data fetched");
                refreshQueries();
            }
            console.log(newPage);
            setPage(newPage);
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    });
    useEffect(() => {
        var _a, _b;
        fetchData();
        const intervalId = setInterval(fetchData, (_b = (_a = new NinjaConfig().getCfg()) === null || _a === void 0 ? void 0 : _a.POLLING_INTERVAL) !== null && _b !== void 0 ? _b : 10000);
        return () => clearInterval(intervalId);
    }, [page]);
    useEffect(() => {
        if (!socials || socials.length == 0) {
            setPage(0);
        }
    }, [socials]);
    const getStatus = () => {
        if (page > 1) {
            return _jsx("div", Object.assign({ className: "fetcher-status" }, { children: "Synced" }));
        }
        return (_jsxs("div", Object.assign({ className: "fetcher-status" }, { children: ["Fetching blockchain data...", _jsx("div", { className: "fetcher-loader" })] })));
    };
    return (_jsxs("div", Object.assign({ className: "fetcher" }, { children: [getStatus(), _jsxs("div", Object.assign({ className: "fetcher-lastcheck" }, { children: ["Epoch: ", page] }))] })));
}
export default Fetcher;
