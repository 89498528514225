class NinjaConfig {
    constructor() {
        if (NinjaConfig._instance) {
            return NinjaConfig._instance;
        }
        NinjaConfig._instance = this;
    }
    setCfg(config) {
        NinjaConfig._config = config;
    }
    getCfg() {
        return NinjaConfig._config;
    }
}
export default NinjaConfig;
