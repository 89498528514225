import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import "./message-list.css";
function MessageList({ messages, wallet, selectMessageTarget, selectUser, getEncryptionKey, getDecryptedMessage, }) {
    const [encryptionKey, setEncryptionKey] = useState(null);
    const [decryptedMessages, setDecryptedMessages] = useState({});
    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            getEncryptionKey().then((result) => setEncryptionKey(result));
        }
    }, []);
    useEffect(() => {
        if (!encryptionKey)
            return;
        messages.forEach((message) => {
            getDecryptedMessage(encryptionKey === null || encryptionKey === void 0 ? void 0 : encryptionKey.privateKey, message).then((decryptedMessage) => {
                decryptedMessages[message.guid] = decryptedMessage;
                setDecryptedMessages(Object.assign({}, decryptedMessages));
            });
        });
    }, [encryptionKey, messages]);
    if (!messages || messages.length === 0)
        return _jsx("div", Object.assign({ className: "message-list" }, { children: "No messages." }));
    if (!encryptionKey ||
        !decryptedMessages ||
        messages.some((x) => !decryptedMessages[x.guid]))
        return (_jsx("div", Object.assign({ className: "message-list" }, { children: "Messages are encrypted. Sign with your wallet to decrypt private messages" })));
    return (_jsx("div", Object.assign({ className: "message-list" }, { children: messages.map((message) => {
            const decryptedMessageText = decryptedMessages[message.guid];
            console.log("decryptedMessageText");
            console.log(decryptedMessageText);
            return (_jsxs("div", Object.assign({ className: "message" }, { children: [_jsxs("div", { children: [_jsxs("span", Object.assign({ className: "message-from" }, { children: [_jsx("b", { children: "From:" }), _jsx("button", Object.assign({ type: "button", className: "user-button btn btn-link", "data-bs-toggle": "modal", "data-bs-target": "#userModal", onClick: () => selectUser(message.from) }, { children: message.from }))] })), _jsxs("span", Object.assign({ className: "message-to" }, { children: [_jsx("b", { children: "To:" }), _jsx("button", Object.assign({ type: "button", className: "user-button btn btn-link", "data-bs-toggle": "modal", "data-bs-target": "#userModal", onClick: () => selectUser(message.to) }, { children: message.to }))] }))] }), _jsxs("span", Object.assign({ className: "message-date" }, { children: ["Date: ", new Date(message.ts * 1000).toString()] })), _jsx("span", Object.assign({ className: "message-text" }, { children: decryptedMessageText })), _jsx("button", Object.assign({ type: "button", className: `message-button btn btn-sm btn-dark mx-2 ${wallet ? "" : "disabled"}`, "data-bs-toggle": "modal", "data-bs-target": "#createMessageModal", onClick: () => selectMessageTarget(message.from) }, { children: "Reply" }))] }), message.guid));
        }) })));
}
export default MessageList;
