var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCategories } from "./api/categories";
import { getSocialCategories } from "./api/socialcategories";
import { getConfig } from "./api/config";
import { getLocations } from "./api/locations";
import { getMessages } from "./api/messages";
import { getPosts } from "./api/posts";
import { getSocials } from "./api/socials";
import { getSubcategories } from "./api/subcategories";
import { getSublocations } from "./api/sublocations";
import { getUser } from "./api/users";
function fetchConfig() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getConfig();
        return data;
    });
}
function fetchUser(username) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log("fetching user");
        console.log(username);
        const data = yield getUser(username, undefined);
        return data;
    });
}
function fetchUserByStakeAddr(stakeAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getUser(undefined, stakeAddress);
        return data;
    });
}
function fetchPosts(query) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getPosts();
        return data;
    });
}
function fetchSocials(query) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getSocials();
        return data;
    });
}
function fetchMessages(user) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getMessages(user);
        return data;
    });
}
function fetchCategories() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getCategories();
        return data;
    });
}
function fetchSocialCategories() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getSocialCategories();
        return data;
    });
}
function fetchSubcategories() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getSubcategories();
        return data;
    });
}
function fetchLocations() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getLocations();
        return data;
    });
}
function fetchSublocations() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield getSublocations();
        return data;
    });
}
export { fetchConfig, fetchUserByStakeAddr, fetchUser, fetchPosts, fetchSocials, fetchMessages, fetchCategories, fetchSocialCategories, fetchSubcategories, fetchLocations, fetchSublocations, };
