var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import './WalletButton.css';
function WalletButton({ wallet, setWallet, username }) {
    const [wallets] = useState(getWallets);
    function getWallets() {
        let wallets = [];
        if (!window.cardano)
            return wallets;
        for (let walletName in window.cardano) {
            if (window.cardano[walletName].enable)
                wallets.push(window.cardano[walletName]);
        }
        console.log(wallets);
        return wallets;
    }
    function connectWallet(_wallet) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(_wallet);
            if (!wallet) {
                console.log('setting wallet');
                let currentWallet = _wallet;
                setWallet(currentWallet);
            }
        });
    }
    if (wallets.length === 0)
        return _jsx("div", { children: _jsx("button", Object.assign({ className: "btn btn-dark wallet-button", type: "button", id: "wallet-button", onClick: () => alert("No wallet found. Read the FAQ on how to set up a wallet.") }, { children: "Connect Wallet" })) });
    if (wallet)
        return _jsx("div", { children: _jsx("button", Object.assign({ className: "btn btn-dark wallet-button disabled", type: "button", id: "wallet-button", disabled: true }, { children: username ? `Connected as ${username}` : `Connected to ${wallet.name}` })) });
    return (_jsx("div", { children: wallets.length > 1 ?
            _jsxs("div", Object.assign({ className: "dropdown" }, { children: [_jsx("button", Object.assign({ className: "btn btn-dark wallet-button dropdown-toggle", type: "button", id: "connectMenu", "data-bs-toggle": "dropdown", "aria-expanded": "false" }, { children: "Connect Wallet" })), _jsx("ul", Object.assign({ className: "dropdown-menu dropdown-menu-dark dropdown-menu-end", "aria-labelledby": "connectMenu" }, { children: wallets.map(w => _jsx("li", { children: _jsx("button", Object.assign({ className: "dropdown-item", type: "button", onClick: () => connectWallet(w) }, { children: w.name })) }, w.name)) }))] }))
            :
                _jsx("button", Object.assign({ className: "btn btn-dark wallet-button", type: "button", id: "wallet-button", onClick: () => connectWallet(wallets[0]) }, { children: wallet == null ? "Connect Wallet" : wallet.name })) }));
}
export default WalletButton;
