import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './category-list.css';
function CategoryList({ categories, selectCategory }) {
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: "category-list" }, { children: [_jsx("span", Object.assign({ className: "category-list-title" }, { children: "Category" })), _jsx("button", Object.assign({ type: "button", className: "category-button btn btn-link d-block", onClick: () => selectCategory(null) }, { children: "All" }), "cat-all"), categories === null || categories === void 0 ? void 0 : categories.map((category, index) => {
                    if (index < 20)
                        return _jsxs("button", Object.assign({ type: "button", className: "category-button btn btn-link d-block", onClick: () => selectCategory(category.name) }, { children: [category.name, " (", category.count, ")"] }), `cat-${category.name}`);
                    else
                        return '';
                })] })) }));
}
export default CategoryList;
