var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const { encrypt, decrypt, PrivateKey } = require("eciesjs");
const crypto = typeof window !== "undefined" ? window.crypto : require("crypto");
const subtle = crypto.subtle;
import { Buffer } from "buffer";
// create a random symmetric key
// encrypt the symmetric key with the public key from the source
// encrypt the symmetric key with the public key from the destination
// encrypt the message with the symmetric key
// return { key_src, key_dst, iv, message }
function encryptMessage(publicKeyHexSrc, publicKeyHexDst, message) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!publicKeyHexSrc)
            throw new Error("publicKeyHexSrc is required");
        if (!publicKeyHexDst)
            throw new Error("publicKeyHexDst is required");
        if (!message)
            throw new Error("message is required");
        const symmetricKey = yield generateSymmetricKey();
        const symmetricKeyHex = yield getHexFromKey(symmetricKey);
        const iv = generateRandomBytes(12);
        const encryptedMessage = yield encryptWithSymmetricKey(message, symmetricKey, iv);
        const data = Buffer.from(symmetricKeyHex);
        const encryptedSymmetricKeySrc = encrypt(publicKeyHexSrc, data);
        const encryptedSymmetricKeyDst = encrypt(publicKeyHexDst, data);
        return {
            srcKey: encryptedSymmetricKeySrc.toString("hex"),
            dstKey: encryptedSymmetricKeyDst.toString("hex"),
            iv: getHex(iv),
            message: getHex(encryptedMessage),
        };
    });
}
// decrypt the symmetric key with the private key
// decrypt the message with the symmetric key
function decryptMessage(privateKey, encryptedSymmetricKey, iv, message) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!privateKey)
            throw new Error("privateKey is required");
        if (!encryptedSymmetricKey)
            throw new Error("encryptedSymmetricKey is required");
        if (!iv)
            throw new Error("iv is required");
        if (!message)
            throw new Error("message is required");
        const decryptedSymmetricKeyHex = decrypt(privateKey, Buffer.from(encryptedSymmetricKey, "hex"));
        const decryptedSymmetricKey = yield importSymmetricKeyFromHex(decryptedSymmetricKeyHex.toString());
        const decryptedMessage = yield decryptWithSymmetricKey(Buffer.from(message, "hex"), Buffer.from(iv, "hex"), decryptedSymmetricKey);
        return decryptedMessage;
    });
}
function generateRandomBytes(size) {
    const randomValues = new Uint8Array(size);
    crypto.getRandomValues(randomValues);
    return randomValues;
}
function encryptWithSymmetricKey(text, key, iv) {
    return __awaiter(this, void 0, void 0, function* () {
        const textBytes = new TextEncoder().encode(text);
        const algorithm = { name: "AES-GCM", iv: iv };
        const encryptedData = yield subtle.encrypt(algorithm, key, textBytes);
        return encryptedData;
    });
}
function decryptWithSymmetricKey(ciphertext, iv, key) {
    return __awaiter(this, void 0, void 0, function* () {
        const algorithm = { name: "AES-GCM", iv };
        const decryptedData = yield subtle.decrypt(algorithm, key, ciphertext);
        return new TextDecoder().decode(decryptedData); // Convert to string
    });
}
function getHexFromKey(key) {
    return __awaiter(this, void 0, void 0, function* () {
        const rawKeyData = yield crypto.subtle.exportKey("raw", key);
        return getHex(rawKeyData);
    });
}
function getHex(arrayBuffer) {
    const bytes = new Uint8Array(arrayBuffer);
    return bytes.reduce((hex, byte) => hex + byte.toString(16).padStart(2, "0"), "");
}
function importSymmetricKeyFromHex(hexKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const keyData = hexStringToBytes(hexKey);
        const importedKey = yield crypto.subtle.importKey("raw", keyData, { name: "AES-GCM" }, true, ["encrypt", "decrypt"]);
        return importedKey;
    });
}
function hexStringToBytes(hexString) {
    const bytes = new Uint8Array(hexString.length / 2);
    for (let i = 0; i < hexString.length; i += 2) {
        bytes[i / 2] = parseInt(hexString.substr(i, 2), 16);
    }
    return bytes;
}
function generateSymmetricKey() {
    return __awaiter(this, void 0, void 0, function* () {
        const algorithm = { name: "AES-GCM", length: 256 };
        return yield subtle.generateKey(algorithm, true, ["encrypt", "decrypt"]);
    });
}
function generateKeyPairFromPassword(passwordBytes) {
    return __awaiter(this, void 0, void 0, function* () {
        const salt = Uint8Array.from({ length: 16 }, () => 0);
        const keyMaterial = yield crypto.subtle.importKey("raw", passwordBytes, { name: "PBKDF2" }, false, ["deriveBits", "deriveKey"]);
        const derivedBits = yield crypto.subtle.deriveBits({
            name: "PBKDF2",
            hash: "SHA-256",
            salt: salt,
            iterations: 610000,
        }, keyMaterial, 32 * 8);
        const derivedBitsHex = Array.from(new Uint8Array(derivedBits))
            .map((byte) => byte.toString(16).padStart(2, "0"))
            .join("");
        const privateKey = PrivateKey.fromHex(derivedBitsHex);
        const publicKey = privateKey.publicKey;
        return {
            publicKey: publicKey.toHex(),
            privateKey: privateKey.toHex(),
        };
    });
}
export { hexStringToBytes, generateKeyPairFromPassword, encryptMessage, decryptMessage, };
