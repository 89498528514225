import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './create-social-modal.css';
import { useState } from 'react';
function CreateSocialModal({ username, parent, createSocial, socialcategories }) {
    const [formData, setFormData] = useState({});
    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        formData[name] = value;
        setFormData(formData);
    }
    return (_jsx("div", Object.assign({ className: "modal fade", id: "createSocialModal", "data-bs-backdrop": "static", tabIndex: -1, "aria-labelledby": "createSocialModalLabel", "aria-hidden": "true" }, { children: _jsx("div", Object.assign({ className: "modal-dialog modal-xl" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsxs("div", Object.assign({ className: "modal-header" }, { children: [_jsx("h5", Object.assign({ className: "modal-title", id: "createSocialModalLabel" }, { children: "Post social" })), _jsx("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" })] })), _jsxs("div", Object.assign({ className: "modal-body" }, { children: [username ?
                                _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "username", className: "form-label" }, { children: "User name" })), _jsx("input", { type: "text", className: "form-control", id: "username", placeholder: username, disabled: true })] }))
                                :
                                    _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "username", className: "form-label" }, { children: "User name" })), _jsx("input", { type: "text", className: "form-control", id: "username", placeholder: "username", onChange: handleInputChange })] })), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "userinfo", className: "form-label" }, { children: "User info" })), _jsx("textarea", { className: "form-control", id: "userinfo", rows: 10, placeholder: "Include a description about yourself here.\nYou might want to include contact information and a PGP key for secure communication.\nRemember that anyone can see your description and it will be stored in the blockchain forever!", onChange: handleInputChange })] }))] }), _jsx("hr", {}), parent ? _jsx(_Fragment, {}) :
                                (_jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "title", className: "form-label" }, { children: "Title" })), _jsx("input", { type: "text", className: "form-control", id: "title", placeholder: "Post title", onChange: handleInputChange })] }))), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "content", className: "form-label" }, { children: "Content" })), _jsx("textarea", { className: "form-control", id: "content", rows: 10, placeholder: "Your message", onChange: handleInputChange })] })), parent ? _jsx(_Fragment, {}) :
                                (_jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ htmlFor: "socialcategory", className: "form-label" }, { children: "Category" })), _jsx("input", { className: "form-control", list: "socialcategoryOptions", id: "socialcategory", placeholder: "Type an existing category or a new one", onChange: handleInputChange }), _jsx("datalist", Object.assign({ id: "socialcategoryOptions" }, { children: socialcategories ? socialcategories.map((x) => _jsx("option", { value: x.name }, x.name)) : '' }))] })))] })), _jsxs("div", Object.assign({ className: "modal-footer" }, { children: [_jsx("button", Object.assign({ type: "button", className: "btn btn-secondary", "data-bs-dismiss": "modal" }, { children: "Close" })), _jsx("button", Object.assign({ type: "button", className: "btn btn-dark", "data-bs-dismiss": "modal", onClick: () => createSocial(formData.username, formData.userinfo, parent, formData.title, formData.content, formData.socialcategory) }, { children: "Post" }))] }))] })) })) })));
}
export default CreateSocialModal;
