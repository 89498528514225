var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const db = require("../db/dbConnection");
// get name
function getUser(username, stakeAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        const database = db.getDb();
        let user;
        if (stakeAddress)
            user = yield database.findUserByStakeAddr(stakeAddress);
        else
            user = yield database.findUserByName(username);
        if (!user) {
            return null;
        }
        let userDto = {
            user: user.user,
            userinfo: user.userinfo,
            stakeAddress: user.stakeAddress,
            publicKey: user.publicKey,
            ts: user.ts,
        };
        return userDto;
    });
}
export { getUser };
