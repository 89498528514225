var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./App.css";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import "buffer";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Alert from "../alert/alert";
import ItemList from "../item-list/item-list";
import MessageList from "../message-list/message-list";
import SettingsList from "../settings-list/settings-list";
import CreatePostModal from "../create-post-modal/create-post-modal";
import CreateMessageModal from "../create-message-modal/create-message-modal";
import UserModal from "../user-modal/user-modal";
import FaqModal from "../faq-modal/faq-modal";
import NavigationBar from "../navigation-bar/navigation-bar";
import CategoryList from "../category-list/category-list";
import { fetchConfig, fetchUserByStakeAddr, fetchUser, fetchPosts, fetchSocials, fetchMessages, fetchCategories, fetchSocialCategories, fetchSubcategories, fetchLocations, fetchSublocations, } from "../../rest-api";
import { getStakeAddress, getStakeAddressSignature, sendMetadata, } from "../../cardano";
import SocialList from "../social-list/social-list";
import CreateSocialModal from "../create-social-modal/create-social-modal";
import * as Sentry from "@sentry/react";
import NinjaConfig from "../../ninjaConfig";
import ReactGA from "react-ga4";
import Fetcher from "../fetcher/Fetcher";
import { hexStringToBytes, generateKeyPairFromPassword, encryptMessage, decryptMessage, } from "../../encryption";
if (window.location.hostname != "localhost") {
    Sentry.init({
        dsn: "https://1abf1b7466555f2ff66548d908727fcf@o4506706468143104.ingest.sentry.io/4506706470502401",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            "localhost:8080",
            /^https:\/\/decon\.app\//,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
function App() {
    let Display;
    (function (Display) {
        Display[Display["Social"] = 0] = "Social";
        Display[Display["Market"] = 1] = "Market";
        Display[Display["Messages"] = 2] = "Messages";
        Display[Display["Settings"] = 3] = "Settings";
    })(Display || (Display = {}));
    React.useEffect(() => {
        fetchConfig().then((result) => new NinjaConfig().setCfg(result));
        if (window.location.hostname != "localhost") {
            ReactGA.initialize("G-R3SCF8L4NF");
            ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname + window.location.search,
                title: "App",
            });
        }
    }, []);
    const [display, setDisplay] = useState(Display.Social);
    const [socialsQuery, setSocialsQuery] = useState(null);
    const [itemsQuery, setItemsQuery] = useState(null);
    const [userQuery, setUserQuery] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [api, setApi] = useState(null);
    const [username, setUsername] = useState(null);
    const [to, setTo] = useState(null);
    const [parentSocial, setParentSocial] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState(null);
    const [alertLink, setAlertLink] = useState(null);
    const [alertType, setAlertType] = useState(null);
    const { data: config, error: configError, isError: configIsError, isLoading: configIsLoading, } = useQuery("config", () => fetchConfig());
    const { data: user, error: userError, isError: userIsError, isLoading: userIsLoading, } = useQuery(["user", userQuery], () => fetchUser(userQuery));
    const { data: posts, error: postsError, isError: postsIsError, isLoading: postsIsLoading, } = useQuery(["posts", itemsQuery], () => fetchPosts(itemsQuery));
    const { data: socials, error: socialsError, isError: socialsIsError, isLoading: socialsIsLoading, } = useQuery(["socials", socialsQuery], () => fetchSocials(socialsQuery));
    const { data: messages, error: messagesError, isError: messagesIsError, isLoading: messagesIsLoading, } = useQuery(["messages", username], () => fetchMessages(username));
    const { data: categories, error: categoriesError, isError: categoriesIsError, isLoading: categoriesIsLoading, } = useQuery("categories", fetchCategories);
    const { data: socialcategories, error: socialcategoriesError, isError: socialcategoriesIsError, isLoading: socialcategoriesIsLoading, } = useQuery("socialcategories", fetchSocialCategories);
    const { data: subcategories, error: subcategoriesError, isError: subcategoriesIsError, isLoading: subcategoriesIsLoading, } = useQuery("subcategories", fetchSubcategories);
    const { data: locations, error: locationsError, isError: locationsIsError, isLoading: locationsIsLoading, } = useQuery("locations", fetchLocations);
    const { data: sublocations, error: sublocationsError, isError: sublocationsIsError, isLoading: sublocationsIsLoading, } = useQuery("sublocations", fetchSublocations);
    const queryClient = useQueryClient();
    const refreshAllQueries = () => {
        queryClient.invalidateQueries();
    };
    const updateWallet = (_wallet) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        console.log("START updateWallet");
        setWallet(_wallet);
        if (_wallet) {
            let _api = yield _wallet.enable();
            console.log("_api");
            console.log(_api);
            setApi(_api);
            console.log("api");
            console.log(api);
            // get username from stakeAddress of wallet
            console.log("calling getStakeAddr from updateWallet");
            try {
                let stakeAddress = yield getStakeAddress(_api);
                let user = yield fetchUserByStakeAddr(stakeAddress);
                setUsername((_a = user === null || user === void 0 ? void 0 : user.user) !== null && _a !== void 0 ? _a : null);
            }
            catch (error) {
                setAlertText("Error: Your wallet does not implement Cardano CIP-30. Use another wallet to be able to post messages.");
                setAlertType("alert-danger");
                setShowAlert(true);
                throw error;
            }
        }
        else {
            console.log("SETTING API TO NULL!!");
            console.log("SETTING API TO NULL!!");
            console.log("SETTING API TO NULL!!");
            setApi(null);
        }
        ReactGA.event({
            category: "User",
            action: "Connected wallet",
        });
    });
    const selectCategory = (_category) => __awaiter(this, void 0, void 0, function* () {
        setDisplay(Display.Market);
        setItemsQuery(_category);
        ReactGA.event({
            category: "User",
            action: "Clicked a Category",
        });
    });
    const selectSocialCategory = (_category) => __awaiter(this, void 0, void 0, function* () {
        setDisplay(Display.Social);
        setSocialsQuery(_category);
        ReactGA.event({
            category: "User",
            action: "Clicked a SocialCategory",
        });
    });
    const selectUserQuery = (username) => __awaiter(this, void 0, void 0, function* () {
        setUserQuery(username);
    });
    const selectSettings = () => __awaiter(this, void 0, void 0, function* () {
        setDisplay(Display.Settings);
        ReactGA.event({
            category: "User",
            action: "Clicked Settings",
        });
    });
    const selectSocial = () => __awaiter(this, void 0, void 0, function* () {
        setDisplay(Display.Social);
        ReactGA.event({
            category: "User",
            action: "Clicked Social",
        });
    });
    const selectMarket = () => __awaiter(this, void 0, void 0, function* () {
        setDisplay(Display.Market);
        ReactGA.event({
            category: "User",
            action: "Clicked Market",
        });
    });
    const selectMessages = () => __awaiter(this, void 0, void 0, function* () {
        console.log("SELECT MESSAGES");
        setDisplay(Display.Messages);
        ReactGA.event({
            category: "User",
            action: "Clicked Messages",
        });
    });
    const selectMessageTarget = (target) => __awaiter(this, void 0, void 0, function* () {
        console.log("Setting to:" + target);
        setTo(target);
    });
    const selectSocialTarget = (target) => __awaiter(this, void 0, void 0, function* () {
        console.log("Setting to:" + target);
        setParentSocial(target);
    });
    const closeAlert = () => __awaiter(this, void 0, void 0, function* () {
        setShowAlert(false);
        setAlertText(null);
        setAlertType(null);
        setAlertLink(null);
    });
    const getEncryptionKey = () => __awaiter(this, void 0, void 0, function* () {
        console.log("getEncryptionKey called!");
        const userSignedSecret = yield getStakeAddressSignature(api);
        if (userSignedSecret) {
            const keyPair = generateKeyPairFromPassword(hexStringToBytes(userSignedSecret));
            return keyPair;
        }
        return null;
    });
    function getDecryptedMessage(privateKey, encryptedMessage) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!privateKey)
                return "Encrypted";
            const encryptedSymmetricKey = username == encryptedMessage.from
                ? encryptedMessage.srcKey
                : encryptedMessage.dstKey;
            const decryptedMessage = yield decryptMessage(privateKey, encryptedSymmetricKey, encryptedMessage.iv, encryptedMessage.message);
            return decryptedMessage;
        });
    }
    const createPost = (_username, userinfo, title, price, description, category, subcategory, location, sublocation) => __awaiter(this, void 0, void 0, function* () {
        console.log(_username);
        console.log(userinfo);
        ReactGA.event({
            category: "User",
            action: "Started creating a Post",
        });
        const postObj = {
            from: username,
            title: title,
            price: price,
            description: description,
            category: category,
            subcategory: subcategory,
            location: location,
            sublocation: sublocation,
            guid: uuidv4(),
        };
        postObj.type = "post";
        createTransaction(api, postObj, _username, userinfo);
    });
    const createSocial = (_username, userinfo, parent, title, content, socialcategory) => __awaiter(this, void 0, void 0, function* () {
        console.log(_username);
        console.log(userinfo);
        ReactGA.event({
            category: "User",
            action: "Started creating a Social",
        });
        const socialObj = {
            from: username,
            parent: parent,
            title: title,
            content: content,
            category: socialcategory,
            guid: uuidv4(),
        };
        socialObj.type = "social";
        createTransaction(api, socialObj, _username, userinfo);
    });
    const createMessage = (_username, userinfo, _to, message) => __awaiter(this, void 0, void 0, function* () {
        console.log(_username);
        console.log(userinfo);
        console.log(_to);
        console.log(message);
        ReactGA.event({
            category: "User",
            action: "Started creating a Message",
        });
        let signature = yield getStakeAddressSignature(api);
        let keyPair = yield generateKeyPairFromPassword(hexStringToBytes(signature));
        const toUser = yield fetchUser(_to);
        const encryptedMessage = yield encryptMessage(keyPair.publicKey, toUser.publicKey, message);
        const messageObj = {
            from: username,
            to: _to,
            srcKey: encryptedMessage.srcKey,
            dstKey: encryptedMessage.dstKey,
            iv: encryptedMessage.iv,
            message: encryptedMessage.message,
            guid: uuidv4(),
        };
        messageObj.type = "msg";
        createTransaction(api, messageObj, _username, userinfo);
    });
    const createTransaction = (api, metadataJsonObj, _username, userinfo) => __awaiter(this, void 0, void 0, function* () {
        try {
            let transactionHash = yield sendMetadata(api, metadataJsonObj, _username, userinfo);
            setAlertText("Transaction created! Follow your transaction here after a couple of seconds:");
            setAlertType("alert-success");
            setAlertLink(`${config.FOLLOW_TRANSACTION_URL}${transactionHash}`);
            setShowAlert(true);
            ReactGA.event({
                category: "User",
                action: "Created a Transaction",
            });
        }
        catch (err) {
            console.error("Error! Check your account balance, refresh the page and try again? Error: " +
                err);
            console.log(err);
            setAlertText("Error: " + err);
            setAlertType("alert-danger");
            setShowAlert(true);
            ReactGA.event({
                category: "User",
                action: "Failed creating a Transaction",
            });
            throw err;
        }
    });
    const renderContent = function (display) {
        switch (display) {
            case Display.Market:
                return (_jsx(ItemList, { items: posts, wallet: wallet, selectUser: selectUserQuery, selectMessageTarget: selectMessageTarget }));
            case Display.Messages:
                return (_jsx(MessageList, { messages: messages, wallet: wallet, selectUser: selectUserQuery, selectMessageTarget: selectMessageTarget, getEncryptionKey: getEncryptionKey, getDecryptedMessage: getDecryptedMessage }));
            case Display.Settings:
                return (_jsx(SettingsList, { closeSettings: () => setDisplay(Display.Social), refreshQueries: refreshAllQueries }));
            default:
                return (_jsx(SocialList, { socials: socials, wallet: wallet, selectUser: selectUserQuery, selectSocialTarget: selectSocialTarget }));
        }
    };
    if (!new NinjaConfig().getCfg()) {
        return _jsx("span", { children: "Loading..." });
    }
    if (configIsLoading || postsIsLoading || categoriesIsLoading) {
        return _jsx("span", { children: "Loading..." });
    }
    if (postsIsError) {
        return _jsxs("span", { children: ["Error: ", postsError.message] });
    }
    if (categoriesIsError) {
        return _jsxs("span", { children: ["Error: ", categoriesError.message] });
    }
    return (_jsxs("div", { children: [_jsx(Fetcher, { socials: socials, refreshQueries: refreshAllQueries }), _jsx(Header, {}), _jsxs("div", Object.assign({ className: "App" }, { children: [_jsx(NavigationBar, { showMarket: display == Display.Market, selectSocial: selectSocial, selectMarket: selectMarket, selectSettings: selectSettings, selectMessages: selectMessages, wallet: wallet, setWallet: updateWallet, messages: messages, username: username, categories: display == Display.Market ? categories : socialcategories, selectCategory: display == Display.Market ? selectCategory : selectSocialCategory }), _jsxs("div", Object.assign({ className: "d-flex app-contents" }, { children: [_jsx("div", Object.assign({ className: "app-desktop" }, { children: display == Display.Market ? (_jsx(CategoryList, { categories: categories, selectCategory: selectCategory })) : (_jsx(CategoryList, { categories: socialcategories, selectCategory: selectSocialCategory })) })), renderContent(display)] })), showAlert ? (_jsx(Alert, { text: alertText, link: alertLink, type: alertType, closeAlert: closeAlert })) : (""), _jsx(CreatePostModal, { username: username, createPost: createPost, categories: categories, subcategories: subcategories, locations: locations, sublocations: sublocations }), _jsx(CreateSocialModal, { username: username, parent: parentSocial, createSocial: createSocial, socialcategories: socialcategories }), _jsx(CreateMessageModal, { username: username, to: to, createMessage: createMessage }), _jsx(UserModal, { user: user, wallet: wallet, selectMessageTarget: selectMessageTarget }), _jsx(FaqModal, {})] })), _jsx(Footer, {})] }));
}
export default App;
