import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './item-list.css';
function ItemList({ items, wallet, selectMessageTarget, selectUser }) {
    if (!items || items.length === 0)
        return _jsx("div", Object.assign({ className: "item-list" }, { children: "No items found." }));
    return (_jsx("div", Object.assign({ className: "item-list" }, { children: items.map((post) => {
            return _jsxs("div", Object.assign({ className: "item" }, { children: [_jsx("h4", Object.assign({ className: "item-title" }, { children: _jsx("a", Object.assign({ "data-bs-toggle": "collapse", href: `#post-${post.guid}`, role: "button", "aria-expanded": "false", "aria-controls": `post-${post.guid}` }, { children: post.title })) })), _jsxs("span", Object.assign({ className: "item-price" }, { children: ["Price: ", post.price] })), _jsxs("div", Object.assign({ id: `post-${post.guid}`, className: "collapse" }, { children: [_jsxs("span", Object.assign({ className: "item-date" }, { children: ["Date: ", new Date(post.ts * 1000).toString()] })), _jsxs("span", Object.assign({ className: "item-category" }, { children: ["Category: ", post.category, " (", post.subcategory, ")"] })), _jsxs("span", Object.assign({ className: "item-location" }, { children: ["Ships from: ", post.location] })), _jsxs("span", Object.assign({ className: "item-location" }, { children: ["Ships to: ", post.sublocation] })), _jsxs("span", Object.assign({ className: "item-description" }, { children: ["Description: ", post.description] })), _jsxs("span", Object.assign({ className: "item-seller" }, { children: ["Seller:", _jsx("button", Object.assign({ type: "button", className: "user-button btn btn-link", "data-bs-toggle": "modal", "data-bs-target": "#userModal", onClick: () => selectUser(post.from) }, { children: _jsx("b", { children: post.from }) }))] })), _jsx("button", Object.assign({ type: "button", className: `item-message-button btn btn-sm btn-dark mx-2 ${wallet ? '' : 'disabled'}`, disabled: !wallet, "data-bs-toggle": "modal", "data-bs-target": "#createMessageModal", onClick: () => selectMessageTarget(post.from) }, { children: "Message Seller" }))] }))] }), post.guid);
        }) })));
}
export default ItemList;
