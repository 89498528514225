var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import PouchDB from "pouchdb-browser";
import pouchdbFind from "pouchdb-find";
PouchDB.plugin(pouchdbFind);
let posts;
let socials;
let users;
let messages;
let guids;
let transactions;
let categories;
let subcategories;
let locations;
let sublocations;
let socialcategories;
let settings;
function initDatabase() {
    posts = new PouchDB(".pouchdb/posts");
    socials = new PouchDB(".pouchdb/socials");
    users = new PouchDB(".pouchdb/users");
    messages = new PouchDB(".pouchdb/messages");
    guids = new PouchDB(".pouchdb/guids");
    transactions = new PouchDB(".pouchdb/transactions");
    categories = new PouchDB(".pouchdb/categories");
    socialcategories = new PouchDB(".pouchdb/socialcategories");
    subcategories = new PouchDB(".pouchdb/subcategories");
    locations = new PouchDB(".pouchdb/locations");
    sublocations = new PouchDB(".pouchdb/sublocations");
    settings = new PouchDB(".pouchdb/settings");
}
initDatabase();
let database = {
    insertPost: function (postObj) {
        postObj._id = postObj.guid;
        return posts.put(postObj);
    },
    insertSocial: function (socialObj) {
        socialObj._id = socialObj.guid;
        return socials.put(socialObj);
    },
    insertUser: function (userObj) {
        userObj._id = userObj.user;
        return users.put(userObj);
    },
    findUserByName: function (name) {
        return __awaiter(this, void 0, void 0, function* () {
            let result = yield users.find({
                selector: { user: name },
            });
            return result.docs[0];
        });
    },
    findUserByStakeAddr: function (addr) {
        return __awaiter(this, void 0, void 0, function* () {
            let result = yield users.find({
                selector: { stakeAddress: addr },
            });
            return result.docs[0];
        });
    },
    insertMessage: function (obj) {
        obj._id = obj.guid;
        return messages.put(obj);
    },
    insertGuid: function (obj) {
        obj._id = obj.guid;
        return guids.put(obj);
    },
    findGuid: function (id) {
        return guids.get(id).catch(function (err) { });
    },
    insertTransaction: function (obj) {
        obj._id = obj.transactionId;
        return transactions.put(obj);
    },
    findTransaction: function (id) {
        return transactions.get(id).catch(function (err) { });
    },
    incrementCategory: function (obj) {
        return __awaiter(this, void 0, void 0, function* () {
            obj._id = obj.category;
            let oldObj = yield categories.get(obj._id).catch(function (err) { });
            if (oldObj) {
                obj._rev = oldObj._rev;
                obj.count = oldObj.count + 1;
            }
            else
                obj.count = 1;
            return categories.put(obj);
        });
    },
    incrementSocialCategory: function (obj) {
        return __awaiter(this, void 0, void 0, function* () {
            obj._id = obj.category;
            let oldObj = yield socialcategories.get(obj._id).catch(function (err) { });
            if (oldObj) {
                obj._rev = oldObj._rev;
                obj.count = oldObj.count + 1;
            }
            else
                obj.count = 1;
            return socialcategories.put(obj);
        });
    },
    incrementSubcategory: function (obj) {
        return __awaiter(this, void 0, void 0, function* () {
            obj._id = obj.subcategory;
            let oldObj = yield subcategories.get(obj._id).catch(function (err) { });
            if (oldObj) {
                obj._rev = oldObj._rev;
                obj.count = oldObj.count + 1;
            }
            else
                obj.count = 1;
            return subcategories.put(obj);
        });
    },
    incrementLocation: function (obj) {
        return __awaiter(this, void 0, void 0, function* () {
            obj._id = obj.location;
            let oldObj = yield locations.get(obj._id).catch(function (err) { });
            if (oldObj) {
                obj._rev = oldObj._rev;
                obj.count = oldObj.count + 1;
            }
            else
                obj.count = 1;
            return locations.put(obj);
        });
    },
    incrementSublocation: function (obj) {
        return __awaiter(this, void 0, void 0, function* () {
            obj._id = obj.sublocation;
            let oldObj = yield sublocations.get(obj._id).catch(function (err) { });
            if (oldObj) {
                obj._rev = oldObj._rev;
                obj.count = oldObj.count + 1;
            }
            else
                obj.count = 1;
            return sublocations.put(obj);
        });
    },
    listPosts: function (category) {
        return __awaiter(this, void 0, void 0, function* () {
            yield posts.createIndex({
                index: { fields: ["ts"] },
            });
            let result = yield posts.find({
                selector: category
                    ? { ts: { $gte: null }, category: category }
                    : { ts: { $gte: null } },
                sort: ["ts"],
                limit: 100,
            });
            return result.docs;
        });
    },
    listSocials: function (category) {
        return __awaiter(this, void 0, void 0, function* () {
            yield socials.createIndex({
                index: { fields: ["ts"] },
            });
            let result = yield socials.find({
                selector: category
                    ? { ts: { $gte: null }, category: category }
                    : { ts: { $gte: null } },
                sort: ["ts"],
                limit: 100,
            });
            return result.docs;
        });
    },
    listMessages: function (user) {
        return __awaiter(this, void 0, void 0, function* () {
            yield messages.createIndex({
                index: { fields: ["ts"] },
            });
            let result = yield messages.find({
                selector: { ts: { $gte: null }, $or: [{ to: user }, { from: user }] },
                sort: ["ts"],
                limit: 100,
            });
            return result.docs;
        });
    },
    listCategories: function () {
        return __awaiter(this, void 0, void 0, function* () {
            yield categories.createIndex({
                index: { fields: ["count"] },
            });
            let result = yield categories.find({
                selector: { count: { $gte: null } },
                sort: ["count"],
                limit: 20,
            });
            return result.docs;
        });
    },
    listSocialCategories: function () {
        return __awaiter(this, void 0, void 0, function* () {
            yield socialcategories.createIndex({
                index: { fields: ["count"] },
            });
            let result = yield socialcategories.find({
                selector: { count: { $gte: null } },
                sort: ["count"],
                limit: 20,
            });
            return result.docs;
        });
    },
    listSubcategories: function () {
        return __awaiter(this, void 0, void 0, function* () {
            yield subcategories.createIndex({
                index: { fields: ["count"] },
            });
            let result = yield subcategories.find({
                selector: { count: { $gte: null } },
                sort: ["count"],
                limit: 20,
            });
            return result.docs;
        });
    },
    listLocations: function () {
        return __awaiter(this, void 0, void 0, function* () {
            yield locations.createIndex({
                index: { fields: ["count"] },
            });
            let result = yield locations.find({
                selector: { count: { $gte: null } },
                sort: ["count"],
                limit: 20,
            });
            return result.docs;
        });
    },
    listSublocations: function () {
        return __awaiter(this, void 0, void 0, function* () {
            yield sublocations.createIndex({
                index: { fields: ["count"] },
            });
            let result = yield sublocations.find({
                selector: { count: { $gte: null } },
                sort: ["count"],
                limit: 20,
            });
            return result.docs;
        });
    },
    insertSettings: function (settingsObj) {
        return __awaiter(this, void 0, void 0, function* () {
            settingsObj._id = "settings";
            let oldObj = yield settings.get(settingsObj._id).catch(function (err) { });
            if (oldObj) {
                settingsObj._rev = oldObj._rev;
            }
            return settings.put(settingsObj, { force: true });
        });
    },
    findSettings: function () {
        return __awaiter(this, void 0, void 0, function* () {
            let dbSettings = yield settings.get("settings").catch(function (err) { });
            return dbSettings;
        });
    },
    clearDatabase: function () {
        return __awaiter(this, void 0, void 0, function* () {
            const savedSettings = yield this.findSettings();
            console.log(savedSettings);
            console.log("Deleting db...");
            Promise.all([
                posts.destroy(),
                socials.destroy(),
                users.destroy(),
                messages.destroy(),
                guids.destroy(),
                transactions.destroy(),
                categories.destroy(),
                socialcategories.destroy(),
                subcategories.destroy(),
                locations.destroy(),
                sublocations.destroy(),
                settings.destroy(),
            ]).then(() => {
                console.log("Starting db...");
                initDatabase();
                database.insertSettings(savedSettings);
            });
        });
    },
};
export const getDb = () => {
    return database;
};
